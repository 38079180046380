import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import bsCustomFileInput from "bs-custom-file-input";
import axios from "axios";
import { Link } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import { SERVERURL } from "../../util/constant";

const BasicElements = () => {
  // state = {
  //   startDate: new Date()
  // };

  // handleChange = date => {
  //   this.setState({
  //     startDate: date
  //   });
  // };

  // componentDidMount() {
  //   bsCustomFileInput.init()
  // }

  const postData = {
    name: "",
    email: "",
    no: "",
    branch: "",
    cgpa: "",
    gender: "",
    roll: "",
    year: "",
    resume: "",
    videoResume: "",
    state: "",
    city: "",
  };
  const [checked, setCheck] = useState(false);

  const updateCheck = () => {
    setCheck(!checked);
  };
  const updatename = (e) => {
    setname(e.target.value);
  };
  const [email, setemail] = useState("");
  const updateemail = (e) => {
    setemail(e.target.value);
  };
  const [phone, setphone] = useState("");
  const updatephone = (e) => {
    setphone(e.target.value);
  };
  const [roll, setRoll] = useState("");
  const updateRoll = (e) => {
    setRoll(e.target.value);
  };
  const [name, setname] = useState("");
  const [gender, setGender] = useState("");
  const [year, setYear] = useState("Select your year");
  const [cgpa, setCgpa] = useState("");
  const [branch, setBranch] = useState("");
  const [resume, setResume] = useState("");
  const [vresume, setVResume] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [submit, setSubmit] = useState("Submit");

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const authToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IjE4MDU2ODdAa2lpdC5hYy5pbiIsImlhdCI6MTYyMDE0ODEyNCwiZXhwIjoxNjIxODc2MTI0fQ.OR9N2PpKMlaHMw62mekePUlFGs6XRbkHd_TAzGb1e4o";

  const loginToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IjE4MDU2ODdAa2lpdC5hYy5pbiIsImlhdCI6MTYyMDIyNjA1NCwiZXhwIjoxNjIxOTU0MDU0fQ.aPU3C3f6GU90zr7sOuPRE-KFHz6IOiVV-IeWyCfsfuc";
  // localStorage.setItem('token', loginToken);
  useEffect(() => {
    console.log(localStorage.getItem("token"));
    axios
      .post(`${SERVERURL}/profile/getProfile`, postData, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        console.log(response);
        console.log(response.data.name);
        setname(response.data.name);
        setemail(response.data.email);
        setBranch(response.data.branch);
        setCgpa(response.data.cgpa);
        setRoll(response.data.roll);
        setYear(response.data.year);
        setphone(response.data.no);
        setGender(response.data.gender);
        setResume(response.data.resume);
        setVResume(response.data.videoResume);
        setState(response.data.state);
        setCity(response.data.city);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit("Please wait...");

    postData.name = name;
    postData.email = email;
    postData.no = phone;
    postData.branch = branch;
    postData.cgpa = cgpa;
    postData.roll = roll;
    postData.year = year;
    postData.gender = gender;
    postData.resume = resume;
    postData.videoResume = vresume;
    postData.city = city;
    postData.state = state;

    if (
      email === "" ||
      name === "" ||
      phone === "" ||
      branch === "" ||
      cgpa === "0.0" ||
      roll === "" ||
      year === "" ||
      year === "Select your year" ||
      year === "" ||
      gender === "" ||
      gender === "Select your gender" ||
      resume === "" ||
      vresume === "" ||
      city === "" ||
      state === ""
    ) {
      swal("Please give valid input", "validation fail", "warning");
      setSubmit("Submit");
      setCheck(false);
    }
    // else if (!validateEmail(email)) {
    //   swal("please enter a valid email", "validation failed", "warning");
    //   setSubmit("Submit");
    //   setCheck(false);
    // }
    else {
      console.log(postData);
      axios
        .post(
          `https://backend.ecell.org.in/api/profile/editProfile`,
          postData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          if (response?.status == 200) {
            swal("Success", "", "success");

            setCheck(false);
            setSubmit("Submit");
            console.log("POST SUCCESSFULL!!!!!!!!!!");
          }
          // swal(response.data.message, "", "warning");
          // setCheck(false);
          // setSubmit("Submit");
        })
        .catch((err) => {
          console.log(err);

          swal("Please give valid input", "validation fail", "warning");
          setSubmit("Submit");
          setCheck(false);
        });
    }
  };

  return (
    <div>
      <div className="page-header">
        {/* <h3 className="page-title"> Form elements </h3> */}
        {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Forms</a></li>
              <li className="breadcrumb-item active" aria-current="page">Form elements</li>
            </ol>
          </nav> */}
      </div>
      {/* <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Default form</h4>
                <p className="card-description"> Basic form layout </p>
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Username</label>
                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Username" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <Form.Control type="email" className="form-control" id="exampleInputEmail1" placeholder="Email" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <Form.Control type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputConfirmPassword1">Confirm Password</label>
                    <Form.Control type="password" className="form-control" id="exampleInputConfirmPassword1" placeholder="Password" />
                  </Form.Group>
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input"/>
                      <i className="input-helper"></i>
                      Remember me
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary mr-2">Submit</button>
                  <button className="btn btn-dark">Cancel</button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Horizontal Form</h4>
                <p className="card-description"> Horizontal form layout </p>
                <form className="forms-sample">
                  <Form.Group className="row">
                    <label htmlFor="exampleInputUsername2" className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                    <Form.Control type="text" className="form-control" id="exampleInputUsername2" placeholder="Username" />
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleInputEmail2" className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                    <Form.Control type="email" className="form-control" id="exampleInputEmail2" placeholder="Email" />
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleInputMobile" className="col-sm-3 col-form-label">Mobile</label>
                    <div className="col-sm-9">
                    <Form.Control type="text" className="form-control" id="exampleInputMobile" placeholder="Mobile number" />
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleInputPassword2" className="col-sm-3 col-form-label">Password</label>
                    <div className="col-sm-9">
                    <Form.Control type="password" className="form-control" id="exampleInputPassword2" placeholder="Password" />
                    </div>
                  </Form.Group>
                  <Form.Group className="row">
                    <label htmlFor="exampleInputConfirmPassword2" className="col-sm-3 col-form-label">Re Password</label>
                    <div className="col-sm-9">
                    <Form.Control type="password" className="form-control" id="exampleInputConfirmPassword2" placeholder="Password" />
                    </div>
                  </Form.Group>
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input"/>
                      <i className="input-helper"></i>
                      Remember me
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary mr-2">Submit</button>
                  <button className="btn btn-dark">Cancel</button>
                </form>
              </div>
            </div>
          </div> */}
      <div className="col-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Profile details</h4>
            <p className="card-description"> Edit Profile </p>
            <form className="forms-sample">
              <Form.Group>
                <label htmlFor="exampleInputName1">Name*</label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  placeholder="Name"
                  value={name}
                  required
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputEmail3">Email address*</label>
                <Form.Control
                  type="email"
                  className="form-control"
                  id="exampleInputEmail3"
                  placeholder="Email"
                  value={email}
                  required
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputPassword4">Roll no.*</label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputPassword4"
                  placeholder="Roll no."
                  value={roll}
                  onChange={updateRoll}
                  required
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputPassword4">Mobile no.*</label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputPassword4"
                  placeholder="Mobile no."
                  value={phone}
                  onChange={updatephone}
                  required
                />
              </Form.Group>
              <Form.Group>
                {/* <label htmlFor="exampleSelectGender">Gender</label>
                    <Form.Control type="text" className="form-control" id="exampleInputPassword4" placeholder="Gender" value={gender} onChange={(e)=>setGender(e.target.value)} required/> */}
                <label htmlFor="exampleFormControlSelect1">Gender*</label>
                <select
                  className="form-control form-control-lg"
                  id="exampleFormControlSelect1"
                  placeholder="Gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  required
                >
                  <option>Select your gender</option>
                  <option>M</option>
                  <option>F</option>
                </select>
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleSelectGender">Year*</label>
                <select
                  className="form-control form-control-lg"
                  id="exampleInputPassword4"
                  placeholder="Year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  required
                >
                  <option>Select your year</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleSelectGender">Branch*</label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputPassword4"
                  placeholder="Branch"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleSelectGender">CGPA*</label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputPassword4"
                  placeholder="CGPA"
                  value={cgpa}
                  onChange={(e) => setCgpa(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group>
                <label>Resume link*</label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputPassword4"
                  placeholder="Kindly give public link with access of ' anyone can view with this link'"
                  value={resume}
                  onChange={(e) => setResume(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group>
                <label>
                  Video Resume link *
                  <a
                    href="https://docs.google.com/document/d/1c-roFZya5uLsLZu98xetCLViUBmQDDscPY_W4Seb0c4/edit?usp=sharing"
                    target="_blank"
                    style={{ marginLeft: "20px" }}
                  >
                    <InfoIcon />
                  </a>
                </label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputPassword4"
                  placeholder="Kindly give public link with access of ' anyone can view with this link'"
                  value={vresume}
                  onChange={(e) => setVResume(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputCity1">City*</label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputCity1"
                  placeholder="City"
                  required
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label htmlFor="exampleInputCity1">State*</label>
                <Form.Control
                  type="text"
                  className="form-control"
                  id="exampleInputCity1"
                  placeholder="State"
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Form.Group>
              {/* <Form.Group>
                    <label htmlFor="exampleTextarea1">Textarea</label>
                    <textarea className="form-control" id="exampleTextarea1" rows="4"></textarea>
                  </Form.Group> */}
              <Form.Group>
                <td>
                  <div className="form-check form-check-muted m-0">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={() => setCheck(!checked)}
                        checked={checked}
                      />
                      <i className="input-helper"></i>I hereby declare that all
                      information provided is true to the best of my knowledge.
                      I also affirm that if I am selected for any internship, I
                      will perform my duties to the best of my skill.I
                      understand that any and all internship offers are solely
                      at the discretion and the responsibility of the company
                      and KIIT E-CELL is not responsible for the selection
                      process in any way whatsoever.
                    </label>
                  </div>
                </td>
              </Form.Group>

              <button
                type="submit"
                className="btn btn-primary mr-2"
                onClick={handleSubmit}
                disabled={checked ? false : true}
              >
                {submit}
              </button>
              {/* <button className="btn btn-dark">Cancel</button> */}
            </form>
          </div>
        </div>
      </div>
      {/* <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Input size</h4>
                <p className="card-description"> Add classNames like <code>.form-control-lg</code> and <code>.form-control-sm</code>. </p>
                <Form.Group>
                  <label>Large input</label>
                  <Form.Control type="text" className="form-control-lg" placeholder="Username" aria-label="Username" />
                </Form.Group>
                <Form.Group>
                  <label>Default input</label>
                  <Form.Control type="text" className="form-control" placeholder="Username" aria-label="Username" />
                </Form.Group>
                <Form.Group>
                  <label>Small input</label>
                  <Form.Control type="text" className="form-control-sm" placeholder="Username" aria-label="Username" />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Select size</h4>
                <p className="card-description"> Add classNamees like <code>.form-control-lg</code> and <code>.form-control-sm</code>. </p>
                <Form.Group>
                  <label htmlFor="exampleFormControlSelect1">Large select</label>
                  <select className="form-control form-control-lg" id="exampleFormControlSelect1">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleFormControlSelect2">Default select</label>
                  <select className="form-control" id="exampleFormControlSelect2">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="exampleFormControlSelect3">Small select</label>
                  <select className="form-control form-control-sm" id="exampleFormControlSelect3">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Basic input groups</h4>
                <p className="card-description"> Basic bootstrap input groups </p>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">@</span>
                    </div>
                    <Form.Control type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-primary text-white">$</span>
                    </div>
                    <Form.Control type="text" className="form-control" aria-label="Amount (to the nearest dollar)" />
                    <div className="input-group-append">
                      <span className="input-group-text">.00</span>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text">0.00</span>
                    </div>
                    <Form.Control type="text" className="form-control" aria-label="Amount (to the nearest dollar)" />
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="input-group">
                  <Form.Control type="text" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                      <button className="btn btn-sm btn-primary" type="button">Search</button>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button className="btn btn-sm btn-outline-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropdown</button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="!#" onClick={event => event.preventDefault()}>Action</a>
                        <a className="dropdown-item" href="!#" onClick={event => event.preventDefault()}>Another action</a>
                        <a className="dropdown-item" href="!#" onClick={event => event.preventDefault()}>Something else here</a>
                        <div role="separator" className="dropdown-divider"></div>
                        <a className="dropdown-item" href="!#" onClick={event => event.preventDefault()}>Separated link</a>
                      </div>
                    </div>
                    <Form.Control type="text" className="form-control" aria-label="Text input with dropdown button" />
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className="input-group">
                    <Form.Control type="text" className="form-control" placeholder="Find in facebook" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                      <div className="input-group-append">
                        <button className="btn btn-sm btn-facebook" type="button">
                          <i className="mdi mdi-facebook"></i>
                        </button>
                      </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Checkbox Controls</h4>
                <p className="card-description">Checkbox and radio controls (default appearance is in primary color)</p>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input"/>
                            <i className="input-helper"></i>
                            Default
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="checkbox" defaultChecked className="form-check-input"/>
                            <i className="input-helper"></i>
                            Checked
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="checkbox" disabled className="form-check-input"/>
                            <i className="input-helper"></i>
                            Disabled
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="checkbox" disabled defaultChecked className="form-check-input"/>
                            <i className="input-helper"></i>
                            Disabled checked
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value=""/>
                            <i className="input-helper"></i>
                            Default
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios2" value="option2" defaultChecked/>
                            <i className="input-helper"></i>
                            Selected
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="optionsRadios2" id="optionsRadios3" value="option3" disabled/>
                            <i className="input-helper"></i>
                            Disabled
                          </label>
                        </div>
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="optionsRadios2" id="optionsRadios4" value="option4" disabled defaultChecked/>
                            <i className="input-helper"></i>
                            Selected and disabled 
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </form>
              </div>
              <div className="card-body">
                <p className="card-description">Add className <code>.form-check-&#123;color&#123;</code> for checkbox and radio controls in theme colors</p>
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <div className="form-check form-check-primary">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input" defaultChecked /> Primary 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        <div className="form-check form-check-success">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input" defaultChecked /> Success 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        <div className="form-check form-check-info">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input" defaultChecked /> Info 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        <div className="form-check form-check-danger">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input" defaultChecked /> Danger 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        <div className="form-check form-check-warning">
                          <label className="form-check-label">
                            <input type="checkbox" className="form-check-input" defaultChecked /> Warning 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <div className="form-check form-check-primary">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="ExampleRadio1" id="ExampleRadio1" defaultChecked /> Primary 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        <div className="form-check form-check-success">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="ExampleRadio2" id="ExampleRadio2" defaultChecked /> Success 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        <div className="form-check form-check-info">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="ExampleRadio3" id="ExampleRadio3" defaultChecked /> Info 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        <div className="form-check form-check-danger">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="ExampleRadio4" id="ExampleRadio4" defaultChecked /> Danger 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        <div className="form-check form-check-warning">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="ExampleRadio5" id="ExampleRadio5" defaultChecked /> Warning 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Inline forms</h4>
                <p className="card-description"> Use the <code>.form-inline</code> className to display a series of labels, form controls, and buttons on a single horizontal row </p>
                <form className="form-inline">
                  <label className="sr-only" htmlFor="inlineFormInputName2">Name</label>
                  <Form.Control  type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Jane Doe" />
                  <label className="sr-only" htmlFor="inlineFormInputGroupUsername2">Username</label>
                  <div className="input-group mb-2 mr-sm-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">@</div>
                    </div>
                    <Form.Control  type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Username" />
                  </div>
                  <div className="form-check mx-sm-2">
                    <label className="form-check-label">
                      <input type="checkbox" className="form-check-input" defaultChecked/> Remember me 
                      <i className="input-helper"></i>
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary mb-2">Submit</button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Horizontal Two column</h4>
                <form className="form-sample">
                  <p className="card-description"> Personal info </p>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">First Name</label>
                        <div className="col-sm-9">
                        <Form.Control  type="text" />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Last Name</label>
                        <div className="col-sm-9">
                        <Form.Control type="text" />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Gender</label>
                        <div className="col-sm-9">
                          <select className="form-control">
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Date of Birth</label>
                        <div className="col-sm-9">
                        <DatePicker className="form-control w-100"
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                        />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Category</label>
                        <div className="col-sm-9">
                          <select className="form-control">
                            <option>Category1</option>
                            <option>Category2</option>
                            <option>Category3</option>
                            <option>Category4</option>
                          </select>
                        </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Membership</label>
                        <div className="col-sm-4">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="ExampleRadio4" id="membershipRadios1" defaultChecked /> Free 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        </div>
                        <div className="col-sm-5">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="ExampleRadio4" id="membershipRadios2" /> Proffessional 
                            <i className="input-helper"></i>
                          </label>
                        </div>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <p className="card-description"> Address </p>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Address 1</label>
                        <div className="col-sm-9">
                        <Form.Control type="text"/>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">State 1</label>
                        <div className="col-sm-9">
                        <Form.Control type="text"/>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Address 2</label>
                        <div className="col-sm-9">
                        <Form.Control type="text"/>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Postcode</label>
                        <div className="col-sm-9">
                        <Form.Control type="text"/>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="row">
                        <label className="col-sm-3 col-form-label">Cirt</label>
                        <div className="col-sm-9">
                        <Form.Control type="text"/>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="row">  
                        <label className="col-sm-3 col-form-label">Country</label>
                        <div className="col-sm-9">
                          <select className="form-control">
                            <option>America</option>
                            <option>Italy</option>
                            <option>Russia</option>
                            <option>Britain</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default BasicElements;
