import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import Spinner from '../app/shared/Spinner';
import Dashboard from './dashboard/Dashboard';
import Typography from './basic-ui/Typography';
import BasicElements from './form-elements/BasicElements';
import Login from './user-pages/Login';
import Forgot from './user-pages/ForgotPassword';

// const Dashboard = lazy(() => import('./dashboard/Dashboard'));

// const Buttons = lazy(() => import('./basic-ui/Buttons'));
// const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
// const Typography = lazy(() => import('./basic-ui/Typography'));

// const BasicElements = lazy(() => import('./form-elements/BasicElements'));

// const BasicTable = lazy(() => import('./tables/BasicTable'));

// const Mdi = lazy(() => import('./icons/Mdi'));

// const ChartJs = lazy(() => import('./charts/ChartJs'));

// const Error404 = lazy(() => import('./error-pages/Error404'));
// const Error500 = lazy(() => import('./error-pages/Error500'));

// const Login = lazy(() => import('./user-pages/Login'));
// const Forgot = lazy(() => import('./user-pages/ForgotPassword'));
// const Register1 = lazy(() => import('./user-pages/Register'));

class AppRoutes extends Component {
	render() {
		return (
			// <Suspense fallback={<Spinner />}>
			<Switch>
				{/* //useful  new route */}
				<Route exact path="/login" component={Login} />
				<Route exact path="/forgot" component={Forgot} />
				<ProtectedRoute exact path="/dashboard" component={Dashboard} />
				<ProtectedRoute exact path="/updateprofile" component={BasicElements} />
				<ProtectedRoute exact path="/selectcompany" component={Typography} />

				{/* Redirect tonew route in case some one hased used it */}
				<Redirect from="/form-Elements/basic-elements" to="/updateprofile" />
				<Redirect from="/basic-ui/typography" to="/selectcompany" />

				<ProtectedRoute path="/basic-ui/typography" component={Typography} />
				<Redirect from="/form-Elements/basic-elements" to="/updateprofile" />
				<Redirect from="/basic-ui/typography" to="/selectcompany" />

				{/* <Route path="/basic-ui/buttons" component={Buttons} /> */}
				{/* <Route path="/basic-ui/dropdowns" component={Dropdowns} />
					<Route path="/basic-ui/typography" component={Typography} /> */}
				{/* <Route
						path="/form-Elements/basic-elements"
						component={BasicElements}
					/> */}
				<ProtectedRoute path="/updateprofile" component={BasicElements} />
				{/* 					
					<Route path="/tables/basic-table" component={BasicTable} />
					<Route path="/icons/mdi" component={Mdi} />
					<Route path="/charts/chart-js" component={ChartJs} />
					<Route path="/user-pages/register-1" component={Register1} />
					<Route path="/error-pages/error-404" component={Error404} />
					<Route path="/error-pages/error-500" component={Error500} /> */}
				<Redirect to="/login" />
			</Switch>
			// </Suspense>s
		);
	}
}

export default AppRoutes;
