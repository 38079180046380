import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Avatar } from "@material-ui/core";
import { SERVERURL } from "../../util/constant";

// import SingleCard from "./SingleCard";

const SingleCard = ({ company, index }) => {
  const [companies, setCompanies] = useState([]);
  const [domain, setDomain] = useState("Select your domain");
  const [status, setStatus] = useState(true);
  const [event, setEvent] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [unique_resume, setUnique_resume] = useState("");
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  console.log(status);
  // const splitCompany = (companie) => {
  //   companie.forEach((company) => {
  //     company.domains = company.domains[0].split(",");
  //   });
  //   setCompanies(companie);
  // };

  // useEffect(() => {
  //   fetch(`${SERVERURL}/auth/getAllCompanies`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       // console.log(data);
  //       // setCompanies(data);
  //       splitCompany(data);
  //     })
  //     .then(() => {
  //       //splitCompany(companies);
  //     });
  // }, []);

  // useEffect(() => {
  // 	console.log(localStorage.getItem('token'));
  // 	axios
  // 		.post(`https://icamp.ecell.org.in/api/profile/getProfile`, postData, {
  // 			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  // 		})
  // 		.then((response) => {
  // 			setEvent(response.data.event);
  // 			// if (response.data.profileCompleted) {
  // 			// 	setStatus(true);
  // 			// } else {
  // 			// 	setStatus(false);
  // 			// }
  // 		})
  // 		.catch((err) => {
  // 			console.log(err);
  // 		});
  // }, []);

  const postData = {
    companyId: "",
    domain: "",
  };
  const postDataWithResume = {
    companyId: company._id,
    domain: domain,
    resume: unique_resume,
  };
  const handleApply = (cmp, dmn) => {
    console.log(cmp._id);
    console.log(dmn);
    postData.companyId = cmp._id;
    postData.domain = dmn;

    if (dmn === "Select your domain") {
      swal("Please select your domain to apply", "", "warning");
    } else {
      console.log(unique_resume);
      console.log(company);
      axios
        .post(
          `${SERVERURL}/auth/applycompany`,
          unique_resume ? postDataWithResume : postData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          swal("DONE!!", "", "warning");
          console.log(res);

          // if (response.status == 200) {
          //   swal(response.data.message, "", "warning");
          // }
          // console.log(response);
        })
        .catch((err) => {
          // console.log(err.response);
          if (err.response.status == 409) {
            swal("You have already applied to this company", "", "warning");
          } else if (err.response.status == 410) {
            swal("You are currently not eligible to apply", "", "warning");
          } else if (err.response.status == 403) {
            swal(`${err.response.data.message}`, "", "warning");
          } else if (err.response.status === 411) {
            swal(`${err.response.data.message}`, "", "warning");
          } else if (err.response.status == 402) {
            swal(`${err.response.data.message}`, "", "warning");
          } else if (err.response.status == 401) {
            swal(`${err.response.data.message}`, "", "warning");
          }
          console.log(err);
        });
    }
  };

  const imageIncomming=company.image_url.split('/d/')[1];
  const imgURL ="https://drive.google.com/uc?id="+ imageIncomming;
  const finalURL = imgURL.replace("/view?usp=sharing","")
  return (
    <div className="card-body text-light">
      <img 
        type="image/x-icon"
        src={`${finalURL}`}
        style={{
          width: "5rem",
          height: "5rem",
          alignSelf: "center",
          backgroundColor:"#ffffff"
        }}
      />
      <br />
      <br />
      <h4 className="card-title">{company.company_name}</h4>
      <h6>Stipend: {company.stipend}</h6>
      {company.stipend_type === "yes" ? (
        <p className="card-description text-light">(Performance based)</p>
      ) : (
        <p></p>
      )}
      <p className="card-description text-light">
        Website Link:{" "}
        <a style={ {color: "#FFC063"}}
          href={
            company.website.includes("http")
              ? company.website
              : `//${company.website}`
          }
          target="_blank"
        >
          {" "}
          Visit Us{" "}
        </a>
      </p>
      <p className="card-description text-light">
        Company Location: {company.company_loc}
      </p>
      <p className="card-description text-light">
        Internship Mode: {company.interview_Mode}
      </p>
      <p>
       <a className="card-description " style={{ color: "#FFC063"}} type="text/javascript" href={
            `${company.description+'/export?format=pdf'}`}
          
          target="_blank">
        Job Description
      </a>
      </p>
      
      <label for="cars">Domains: </label>
      <select
        name="cars"
        id="cars"
        style={{
          height: "30px",
          backgroundColor: "#191C24",
          color: "#FFC063",
        }}
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
      >
        <option>Select your domain</option>
        {company.domains.map((domain, i) => {
          return <option key={i}>{domain}</option>;
        })}
      </select>
      <div className="d-flex pt-2">
        <p>Use Default Resume:</p>
        <div className="d-flex pt-1 pl-1">
          <input
            type="checkbox"
            id="resume"
            name="resume choosing"
            value="Change"
            checked={isChecked}
            onChange={handleOnChange}
          />
        </div>
      </div>
      {!isChecked ? (
        <div>
          <form>
            <label>
              Resume Link:
              <input
                value={unique_resume}
                type="text"
                onChange={(e) => setUnique_resume(e.target.value)}
              />
            </label>
          </form>
        </div>
      ) : (
        <div></div>
      )}
      <p className="card-description text-light">
        Once applied, you cannot remove this company
      </p>
      <button
        type="button"
        class="btn btn-warning"
        style={{color:"black"}}
       
        onClick={() => {
          handleApply(company, domain);
        }}
        disabled={company.seat_remaining > 0 ? false : true}
      >
        {company.seat_remaining > 0 ? `Apply` : `seat full`}
      </button>
    </div>
  );
};

export default SingleCard;
