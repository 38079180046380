import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { SERVERURL } from "../../util/constant";
import swal from "sweetalert";
import axios from "axios";
import { connect } from "react-redux";
import { setUser } from "../actions";

function Login(props) {
  const [loginData, setLoginData] = React.useState({ email: "", password: "" });
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const loginSumbit = (e) => {
    e.preventDefault();
    if (!validateEmail(loginData.email)) {
      swal("Please give valid input", "validation fail", "warning");
    }
    axios
      .post(`${SERVERURL}/auth/login`, loginData)
      .then((response) => {
        if (response.status === 200) {
          //verfication success
          const ndata = {
            email: loginData.email,
            name: response.data.name,
            token: response.data.token,
          };
          localStorage.setItem("token", response.data.token);
          props.setUser(ndata);

          setTimeout(function () {
            swal("Success", "Login Successful", "success");
          }, 50);
          setTimeout(function () {
            window.location.href = "/dashboard";
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          swal(
            "An Email has been sent to you.",
            "please verify your email",
            "warning"
          );
        } else if (err.response.status === 400) {
          swal(
            "Registration not done",
            "please register and then login",
            "warning"
          );
        } else if (err.response.status === 401) {
          swal("Password does not match", "", "warning");
        } else if (err.response.status === 404) {
          swal("email not found", "", "warning");
        } else if (err.response.status === 405) {
          swal("permission denied", "", "warning");
        } else if (err.response.status === 402) {
          swal("only kiit student allowed", "", "warning");
        } else if (err.response.status === 410) {
          swal("not paid ", "try next year", "warning");
        } else {
          swal("server error", "", "error");
        }
      });
    console.log(loginData);

    
    //  swal("Company Selection Process has ended","Please try next year", "warning");
     
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        allignItem: "center",
        backgroundImage: "linear-gradient(#fefeff,#39ACE3)",
      }}
    >
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-4 mx-auto">
            <div className="card text-left py-5 px-4 px-sm-5">
              <div
                className="brand-logo"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={require("../../assets/images/ICAMP.png")}
                  alt="logo"
                  style={{
                    height: "100px",
                    width: "120px",
                  }}
                />
              </div>

              <h6
                className="font-weight-light"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Sign in to continue.
              </h6>
              <Form className="pt-3">
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Kiit email id"
                    size="lg"
                    style={{ color: "white" }}
                    className="h-auto"
                    onChange={(e) =>
                      setLoginData({ ...loginData, email: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group className="d-flex search-field">
                  <Form.Control
                    type="password"
                    style={{ color: "white" }}
                    placeholder="Password"
                    size="lg"
                    className="h-auto"
                    onChange={(e) =>
                      setLoginData({ ...loginData, password: e.target.value })
                    }
                  />
                </Form.Group>
                <div className="mt-3">
                  {/* <Link
										className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
										to="/dashboard"
									> */}
                  <button
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={loginSumbit}
                  >
                    SIGN IN
                  </button>

                  {/* </Link> */}
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                  <div className="form-check">
                    <label className="form-check-label text-muted">
                      <input type="checkbox" className="form-check-input" />
                      <i className="input-helper"></i>
                      Keep me signed in
                    </label>
                  </div>

                  <a
                    href="/forgot"
                    onClick={(event) => event.preventDefault()}
                    className="auth-link text-muted"
                  >
                    <Link to="/forgot"> Forgot password?</Link>
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

//export default Login;
