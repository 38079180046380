import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import { Doughnut } from 'react-chartjs-2';
// import Slider from 'react-slick';
// import { TodoListComponent } from '../apps/TodoList';
// import { VectorMap } from 'react-jvectormap';
import { Avatar } from '@material-ui/core';
import { connect } from 'react-redux';
import { setUser } from '../actions';
import { SERVERURL } from '../../util/constant';
import "./Dashboard.css"

const mapData = {
	BZ: 75.0,
	US: 56.25,
	AU: 15.45,
	GB: 25.0,
	RO: 10.25,
	GE: 33.25,
};

function Dashboard() {
	const postData = {
		name: '',
		email: '',
		no: NaN,
		branch: '',
		cgpa: '',
		gender: '',
		roll: '',
		year: '',
		resume: '',
		videoResume: '',
		state: '',
		city: '',
	};
	const [status, setStatus] = useState(false);

	const [name, setname] = useState('');
	const [checked, setCheck] = useState(false);

	const updateCheck = () => {
		setCheck(!checked);
	};
	const updatename = (e) => {
		setname(e.target.value);
	};
	const [email, setemail] = useState('');
	const updateemail = (e) => {
		setemail(e.target.value);
	};
	const [phone, setphone] = useState('');
	const updatephone = (e) => {
		setphone(e.target.value);
	};
	const [roll, setRoll] = useState('');
	const updateRoll = (e) => {
		setRoll(e.target.value);
	};
	const [gender, setGender] = useState('');
	const [year, setYear] = useState('');
	const [cgpa, setCgpa] = useState('');
	const [branch, setBranch] = useState('');
	const [resume, setResume] = useState('');
	const [vresume, setVResume] = useState('');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [companies, setCompanies] = useState([]);
	const [event, setEvent] = useState();
	// const loginToken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IjE4MDU2ODdAa2lpdC5hYy5pbiIsImlhdCI6MTYyMDIyNjA1NCwiZXhwIjoxNjIxOTU0MDU0fQ.aPU3C3f6GU90zr7sOuPRE-KFHz6IOiVV-IeWyCfsfuc"
	//   localStorage.setItem('token',loginToken);
	useEffect(() => {
		console.log(localStorage.getItem('token'));
		axios
			.post(`${SERVERURL}/profile/getProfile`, postData, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((response) => {
				localStorage.setItem('name', response.data.name);
				localStorage.setItem('event', response.data.event);
				console.log(response);
				console.log(response.data.name);
				setEvent(response.data.event);
				setname(response.data.name);
				setemail(response.data.email);
				setBranch(response.data.branch);
				setCgpa(response.data.cgpa);
				setRoll(response.data.roll);
				setYear(response.data.year);
				setphone(response.data.no);
				setGender(response.data.gender);
				setResume(response.data.resume);
				setVResume(response.data.videoResume);
				setState(response.data.state);
				setCity(response.data.city);
				setCompanies(response.data.company);
				if (response.data.profileCompleted) {
					setStatus(true);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const getProfile = () => {
		axios
			.post(`https://backend.ecell.org.in/api/profile/getProfile`, postData, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((response) => {
				console.log(response);
				console.log(response.data.name);
				setname(response.data.name);
				setemail(response.data.email);
				setBranch(response.data.branch);
				setCgpa(response.data.cgpa);
				setRoll(response.data.roll);
				setYear(response.data.year);
				setphone(response.data.no);
				setGender(response.data.gender);
				setResume(response.data.resume);
				setVResume(response.data.videoResume);
				setState(response.data.state);
				setCity(response.data.city);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// const transactionHistoryOptions = {
	//   responsive: true,
	//   maintainAspectRatio: true,
	//   segmentShowStroke: false,
	//   cutoutPercentage: 70,
	//   elements: {
	//     arc: {
	//         borderWidth: 0
	//     }
	//   },
	//   legend: {
	//     display: false
	//   },
	//   tooltips: {
	//     enabled: true
	//   }
	// }

	// const sliderSettings = {
	//   infinite: true,
	//   speed: 500,
	//   slidesToShow: 1,
	//   slidesToScroll: 1
	// }
	// const toggleProBanner() {
	//   document.querySelector('.proBanner').classList.toggle("hide");
	// }
	const profileStatus = () => {
		console.log(status);
		if (status == true && event === 'Icamp') {
			return <p>Company selection process will start soon...</p>;
		} else {
			return (
				<p>Please complete your profile to select companies of your choice</p>
			);
		}
	};
	return (
		<div>
			{/* <div className="proBanner">
          <div>
            <span className="d-flex align-items-center purchase-popup">
              <p>Get tons of UI components, Plugins, multiple layouts, 20+ sample pages, and more!</p>
              <a href="https://www.bootstrapdash.com/product/corona-react/?utm_source=organic&utm_medium=banner&utm_campaign=free-preview" rel="noopener noreferrer" target="_blank" className="btn btn-sm purchase-button ml-auto">Check Pro Version</a>
              <i className="mdi mdi-close bannerClose" onClick={this.toggleProBanner}></i>
            </span>
          </div>
        </div> */}
			{/* <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card corona-gradient-card">
              <div className="card-body py-0 px-0 px-sm-3">
                <div className="row align-items-center">
                  <div className="col-4 col-sm-3 col-xl-2">
                    <img src={require('../../assets/images/dashboard/Group126@2x.png')} className="gradient-corona-img img-fluid" alt="banner" />
                  </div>
                  <div className="col-5 col-sm-7 col-xl-8 p-0">
                    <h4 className="mb-1 mb-sm-0">New refreshing look</h4>
                    <p className="mb-0 font-weight-normal d-none d-sm-block">Corona admin template now with a new facelift for enhanced legibility and aesthetics!</p>
                  </div>
                  <div className="col-3 col-sm-2 col-xl-2 pl-0 text-center">
                    <button className="btn btn-outline-light btn-rounded get-started-btn">Get Started</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-sm-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-9">
                    <div className="d-flex align-items-center align-self-start">
                      <h3 className="mb-0">$12.34</h3>
                      <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="icon icon-box-success ">
                      <span className="mdi mdi-arrow-top-right icon-item"></span>
                    </div>
                  </div>
                </div>
                <h6 className="text-muted font-weight-normal">Potential growth</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-9">
                    <div className="d-flex align-items-center align-self-start">
                      <h3 className="mb-0">$17.34</h3>
                      <p className="text-success ml-2 mb-0 font-weight-medium">+11%</p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="icon icon-box-success">
                      <span className="mdi mdi-arrow-top-right icon-item"></span>
                    </div>
                  </div>
                </div>
                <h6 className="text-muted font-weight-normal">Revenue current</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-9">
                    <div className="d-flex align-items-center align-self-start">
                      <h3 className="mb-0">$12.34</h3>
                      <p className="text-danger ml-2 mb-0 font-weight-medium">-2.4%</p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="icon icon-box-danger">
                      <span className="mdi mdi-arrow-bottom-left icon-item"></span>
                    </div>
                  </div>
                </div>
                <h6 className="text-muted font-weight-normal">Daily Income</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-9">
                    <div className="d-flex align-items-center align-self-start">
                      <h3 className="mb-0">$31.53</h3>
                      <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="icon icon-box-success ">
                      <span className="mdi mdi-arrow-top-right icon-item"></span>
                    </div>
                  </div>
                </div>
                <h6 className="text-muted font-weight-normal">Expense current</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Transaction History</h4>
                <div className="aligner-wrapper">
                  <Doughnut data={this.transactionHistoryData} options={this.transactionHistoryOptions} />
                  <div className="absolute center-content">
                    <h5 className="font-weight-normal text-whiite text-center mb-2 text-white">1200</h5>
                    <p className="text-small text-muted text-center mb-0">Total</p>
                  </div>
                </div>  
                <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                  <div className="text-md-center text-xl-left">
                    <h6 className="mb-1">Transfer to Paypal</h6>
                    <p className="text-muted mb-0">07 Jan 2019, 09:12AM</p>
                  </div>
                  <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                    <h6 className="font-weight-bold mb-0">$236</h6>
                  </div>
                </div>
                <div className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3">
                  <div className="text-md-center text-xl-left">
                    <h6 className="mb-1">Tranfer to Stripe</h6>
                    <p className="text-muted mb-0">07 Jan 2019, 09:12AM</p>
                  </div>
                  <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                    <h6 className="font-weight-bold mb-0">$593</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                  <h4 className="card-title mb-1">Open Projects</h4>
                  <p className="text-muted mb-1">Your data status</p>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="preview-list">
                      <div className="preview-item border-bottom">
                        <div className="preview-thumbnail">
                          <div className="preview-icon bg-primary">
                            <i className="mdi mdi-file-document"></i>
                          </div>
                        </div>
                        <div className="preview-item-content d-sm-flex flex-grow">
                          <div className="flex-grow">
                            <h6 className="preview-subject">Admin dashboard design</h6>
                            <p className="text-muted mb-0">Broadcast web app mockup</p>
                          </div>
                          <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p className="text-muted">15 minutes ago</p>
                            <p className="text-muted mb-0">30 tasks, 5 issues </p>
                          </div>
                        </div>
                      </div>
                      <div className="preview-item border-bottom">
                        <div className="preview-thumbnail">
                          <div className="preview-icon bg-success">
                            <i className="mdi mdi-cloud-download"></i>
                          </div>
                        </div>
                        <div className="preview-item-content d-sm-flex flex-grow">
                          <div className="flex-grow">
                            <h6 className="preview-subject">Wordpress Development</h6>
                            <p className="text-muted mb-0">Upload new design</p>
                          </div>
                          <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p className="text-muted">1 hour ago</p>
                            <p className="text-muted mb-0">23 tasks, 5 issues </p>
                          </div>
                        </div>
                      </div>
                      <div className="preview-item border-bottom">
                        <div className="preview-thumbnail">
                          <div className="preview-icon bg-info">
                            <i className="mdi mdi-clock"></i>
                          </div>
                        </div>
                        <div className="preview-item-content d-sm-flex flex-grow">
                          <div className="flex-grow">
                            <h6 className="preview-subject">Project meeting</h6>
                            <p className="text-muted mb-0">New project discussion</p>
                          </div>
                          <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p className="text-muted">35 minutes ago</p>
                            <p className="text-muted mb-0">15 tasks, 2 issues</p>
                          </div>
                        </div>
                      </div>
                      <div className="preview-item border-bottom">
                        <div className="preview-thumbnail">
                          <div className="preview-icon bg-danger">
                            <i className="mdi mdi-email-open"></i>
                          </div>
                        </div>
                        <div className="preview-item-content d-sm-flex flex-grow">
                          <div className="flex-grow">
                            <h6 className="preview-subject">Broadcast Mail</h6>
                            <p className="text-muted mb-0">Sent release details to team</p>
                          </div>
                          <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p className="text-muted">55 minutes ago</p>
                            <p className="text-muted mb-0">35 tasks, 7 issues </p>
                          </div>
                        </div>
                      </div>
                      <div className="preview-item">
                        <div className="preview-thumbnail">
                          <div className="preview-icon bg-warning">
                            <i className="mdi mdi-chart-pie"></i>
                          </div>
                        </div>
                        <div className="preview-item-content d-sm-flex flex-grow">
                          <div className="flex-grow">
                            <h6 className="preview-subject">UI Design</h6>
                            <p className="text-muted mb-0">New application planning</p>
                          </div>
                          <div className="mr-auto text-sm-right pt-2 pt-sm-0">
                            <p className="text-muted">50 minutes ago</p>
                            <p className="text-muted mb-0">27 tasks, 4 issues </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 grid-margin">
            <div className="card">
              <div className="card-body">
                <h5>Revenue</h5>
                <div className="row">
                  <div className="col-8 col-sm-12 col-xl-8 my-auto">
                    <div className="d-flex d-sm-block d-md-flex align-items-center">
                      <h2 className="mb-0">$32123</h2>
                      <p className="text-success ml-2 mb-0 font-weight-medium">+3.5%</p>
                    </div>
                    <h6 className="text-muted font-weight-normal">11.38% Since last month</h6>
                  </div>
                  <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                    <i className="icon-lg mdi mdi-codepen text-primary ml-auto"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 grid-margin">
            <div className="card">
              <div className="card-body">
                <h5>Sales</h5>
                <div className="row">
                  <div className="col-8 col-sm-12 col-xl-8 my-auto">
                    <div className="d-flex d-sm-block d-md-flex align-items-center">
                      <h2 className="mb-0">$45850</h2>
                      <p className="text-success ml-2 mb-0 font-weight-medium">+8.3%</p>
                    </div>
                    <h6 className="text-muted font-weight-normal"> 9.61% Since last month</h6>
                  </div>
                  <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                    <i className="icon-lg mdi mdi-wallet-travel text-danger ml-auto"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 grid-margin">
            <div className="card">
              <div className="card-body">
                <h5>Purchase</h5>
                <div className="row">
                  <div className="col-8 col-sm-12 col-xl-8 my-auto">
                    <div className="d-flex d-sm-block d-md-flex align-items-center">
                      <h2 className="mb-0">$2039</h2>
                      <p className="text-danger ml-2 mb-0 font-weight-medium">-2.1% </p>
                    </div>
                    <h6 className="text-muted font-weight-normal">2.27% Since last month</h6>
                  </div>
                  <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                    <i className="icon-lg mdi mdi-monitor text-success ml-auto"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
			{/* <div className="col-md-6 col-xl-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Profile</h4>
                <Slider className="portfolio-slider" {...this.sliderSettings}>
                  <div className="item">
                    <img src={require('../../assets/images/dashboard/Rectangle.jpg')} alt="carousel-item" />
                  </div>
                  <div className="item">
                    <img src={require('../../assets/images/dashboard/Img_5.jpg')} alt="carousel-item" />
                  </div>
                  <div className="item">
                    <img src={require('../../assets/images/dashboard/img_6.jpg')} alt="carousel-item" />
                  </div>
                </Slider>
                <div className="d-flex py-4">
                  <div className="preview-list w-100">
                    <div className="preview-item p-0">
                      <div className="preview-thumbnail">
                        <img src={require('../../assets/images/faces/face12.jpg')} className="rounded-circle" alt="face" />
                      </div>
                      <div className="preview-item-content d-flex flex-grow">
                        <div className="flex-grow">
                          <div className="d-flex d-md-block d-xl-flex justify-content-between">
                            <h6 className="preview-subject">CeeCee Bass</h6>
                            <p className="text-muted text-small">4 Hours Ago</p>
                          </div>
                          <p className="text-muted">Well, it seems to be working now.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <p className="text-muted">Well, it seems to be working now. </p>
                <div className="progress progress-md portfolio-progress">
                  <div className="progress-bar bg-success" role="progressbar" style={{width: '50%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <td style = {{textAlign: "center" }}>
                    <div className="badge badge-outline-success">Edit</div>
                </td>
              </div>
            </div>
          </div> */}

			{/*Selected companies main code */}
			<div class="page-content page-container" id="page-content">
				<div class="padding" style={{ padding: '3rem !important' }}>
					<div class="row container d-flex justify-content-center">
						<div class="col-xl-6 col-md-12 ">
							<div
								class="card user-card-full main-wala-card"
								style={{
									overflow: 'hidden',
									borderRadius: '5px',
									boxShadow: '0 1px 20px 0 rgba(69, 90, 100, 0.08)',
									marginTop: '50px',
									width: '40vw',
								}}
							>
								<div class="row m-l-0 m-r-0" style={{ marginRight: '0px' }}>
									<div
										class="col-sm-4 bg-c-lite-green user-profile"
										style={{ borderRadius: '5px 0 0 5px' }}
									>
										<div class="card-block text-center text-white">
											<div class="m-b-25">
												<form class="main-wala-avatar">
													<Avatar
                          className="main-wala-avatar"
														style={{
															margin: '30px',
															backgroundColor: '#7B68EE',
															width: '4rem',
															height: '4rem',
														}}
													>
														{name.charAt(0)}
													</Avatar>
												</form>
											</div>
											<br />
											<h6 class="f-w-600">{name}</h6>
										</div>
									</div>
									<div class="col-sm-8">
										<div
											class="card-block"
											style={{ lineHeight: '25px', paddingLeft: '1.25rem' }}
										>
											<h6
												class="m-b-20 p-b-5 b-b-default f-w-600"
												style={{
													fontSize: '22px',
													marginBottom: '20px',
													paddingBottom: '5px !important',
													marginTop: '30px',
												}}
											>
												Profile
											</h6>

											<div class="row">
												{roll && (
													<div class="col-sm-6">
														<p class="m-b-10 f-w-600"><strong>Roll:</strong></p>
														<h6 class="text-muted f-w-600">{roll}</h6>
													</div>
												)}
												<div class="col-sm-6">
													<p class="m-b-10 f-w-600"><strong>Gender:</strong> </p>
													<h6 class="text-muted f-w-400">{gender}</h6>
												</div>
											</div>

											<div class="row">
												<div class="col-sm-6">
													<p class="m-b-10 f-w-600"><strong>Email:</strong> </p>
													<h6 class="text-muted f-w-400">{email}</h6>
												</div>
												<div class="col-sm-6">
													<p class="m-b-10 f-w-600"><strong>Phone:</strong> </p>
													<h6 class="text-muted f-w-400">{phone}</h6>
												</div>
											</div>
											<div class="row">
												{year && (
													<div class="col-sm-6">
														<p class="m-b-10 f-w-600"><strong>Year:</strong> </p>
														<h6 class="text-muted f-w-400">{year}</h6>
													</div>
												)}
												{city && (
													<div class="col-sm-6">
														<p class="m-b-10 f-w-600"><strong>Location:</strong></p>
														<h6 class="text-muted f-w-400">
															{city},<br />
															{state}
														</h6>
													</div>
												)}
											</div>
											<div class="row" style={{ marginBottom: '10px' }}>
												{branch && (
													<div class="col-sm-6">
														<p class="m-b-10 f-w-600"><strong> Branch: </strong></p>
														<h6 class="text-muted f-w-600">{branch}</h6>
													</div>
												)}
												{cgpa && (
													<div class="col-sm-6">
														<p class="m-b-10 f-w-600"><strong> CGPA:</strong></p>
														<h6 class="text-muted f-w-400">{cgpa}</h6>
													</div>
												)}
											</div>
											<td style={{ textAlign: 'center', marginBottom: '30px' }}>
												<Link
													className="badge badge-outline-success"
													to="/form-elements/basic-elements"
													style={{
														color: '#7B68EE',
														border: '1px solid #7B68EE',
													}}
												>
													Edit
												</Link>
											</td>
											<br />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br />

			<div className="row ">
				{/* {profileStatus()}   */}
				{/*Selected companies main code */}

				<div className="col-12 grid-margin">
					<div className="card">
						<div className="card-body">
							<h4 className="card-title">Selected companies</h4>
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th></th>
											<th> Company name</th>
											<th> Stipend </th>
											<th> Domain </th>
											<th> Website </th>
										</tr>
									</thead>
									<tbody>
                    
										{ 
                    companies.map((company, i) => {
											console.log(company);
											return (
												<tr>
													<td>
														<div className="d-flex">
															<img
                              
																src={`${company?.companyId[0]?.image_url}`}
																alt="face"
															/>
															<span className="pl-2"></span>
														</div>
													</td>
													<td> {company?.companyId[0]?.company_name}</td>
													<td> {company?.companyId[0]?.stipend} </td>
													<td> {company?.domain}</td>
													<td>
														{' '}
														<a
															href={
																company?.companyId[0]?.website.includes('http')
																	? company?.companyId[0]?.website
																	: `//${company?.companyId[0]?.website}`
															}
															target="_blank"
														>
															Website
														</a>{' '}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{/*End of selected company code */}
			</div>
			<div className="row">
				{/*<div className="col-md-6 col-xl-4 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-row justify-content-between">
                  <h4 className="card-title">Messages</h4>
                  <p className="text-muted mb-1 small">View all</p>
                </div>
                <div className="preview-list">
                  <div className="preview-item border-bottom">
                    <div className="preview-thumbnail">
                      <img src={require('../../assets/images/faces/face6.jpg')} alt="face" className="rounded-circle" />
                    </div>
                    <div className="preview-item-content d-flex flex-grow">
                      <div className="flex-grow">
                        <div className="d-flex d-md-block d-xl-flex justify-content-between">
                          <h6 className="preview-subject">Leonard</h6>
                          <p className="text-muted text-small">5 minutes ago</p>
                        </div>
                        <p className="text-muted">Well, it seems to be working now.</p>
                      </div>
                    </div>
                  </div>
                  <div className="preview-item border-bottom">
                    <div className="preview-thumbnail">
                      <img src={require('../../assets/images/faces/face8.jpg')} alt="face" className="rounded-circle" />
                    </div>
                    <div className="preview-item-content d-flex flex-grow">
                      <div className="flex-grow">
                        <div className="d-flex d-md-block d-xl-flex justify-content-between">
                          <h6 className="preview-subject">Luella Mills</h6>
                          <p className="text-muted text-small">10 Minutes Ago</p>
                        </div>
                        <p className="text-muted">Well, it seems to be working now.</p>
                      </div>
                    </div>
                  </div>
                  <div className="preview-item border-bottom">
                    <div className="preview-thumbnail">
                      <img src={require('../../assets/images/faces/face9.jpg')} alt="face" className="rounded-circle" />
                    </div>
                    <div className="preview-item-content d-flex flex-grow">
                      <div className="flex-grow">
                        <div className="d-flex d-md-block d-xl-flex justify-content-between">
                          <h6 className="preview-subject">Ethel Kelly</h6>
                          <p className="text-muted text-small">2 Hours Ago</p>
                        </div>
                        <p className="text-muted">Please review the tickets</p>
                      </div>
                    </div>
                  </div>
                  <div className="preview-item border-bottom">
                    <div className="preview-thumbnail">
                      <img src={require('../../assets/images/faces/face11.jpg')} alt="face" className="rounded-circle" />
                    </div>
                    <div className="preview-item-content d-flex flex-grow">
                      <div className="flex-grow">
                        <div className="d-flex d-md-block d-xl-flex justify-content-between">
                          <h6 className="preview-subject">Herman May</h6>
                          <p className="text-muted text-small">4 Hours Ago</p>
                        </div>
                        <p className="text-muted">Thanks a lot. It was easy to fix it .</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
				{/* <div className="col-md-12 col-xl-4 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">To do list</h4>
                <TodoListComponent />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Visitors by Countries</h4>
                <div className="row">
                  <div className="col-md-5">
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>
                              <i className="flag-icon flag-icon-us"></i>
                            </td>
                            <td>USA</td>
                            <td className="text-right"> 1500 </td>
                            <td className="text-right font-weight-medium"> 56.35% </td>
                          </tr>
                          <tr>
                            <td>
                              <i className="flag-icon flag-icon-de"></i>
                            </td>
                            <td>Germany</td>
                            <td className="text-right"> 800 </td>
                            <td className="text-right font-weight-medium"> 33.25% </td>
                          </tr>
                          <tr>
                            <td>
                              <i className="flag-icon flag-icon-au"></i>
                            </td>
                            <td>Australia</td>
                            <td className="text-right"> 760 </td>
                            <td className="text-right font-weight-medium"> 15.45% </td>
                          </tr>
                          <tr>
                            <td>
                              <i className="flag-icon flag-icon-gb"></i>
                            </td>
                            <td>United Kingdom</td>
                            <td className="text-right"> 450 </td>
                            <td className="text-right font-weight-medium"> 25.00% </td>
                          </tr>
                          <tr>
                            <td>
                              <i className="flag-icon flag-icon-ro"></i>
                            </td>
                            <td>Romania</td>
                            <td className="text-right"> 620 </td>
                            <td className="text-right font-weight-medium"> 10.25% </td>
                          </tr>
                          <tr>
                            <td>
                              <i className="flag-icon flag-icon-br"></i>
                            </td>
                            <td>Brasil</td>
                            <td className="text-right"> 230 </td>
                            <td className="text-right font-weight-medium"> 75.00% </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div id="audience-map" className="vector-map"></div>
                    <VectorMap
                    map={"world_mill"}
                    backgroundColor="transparent" //change it to ocean blue: #0077be
                    panOnDrag={true}
                    containerClassName="dashboard-vector-map"
                    focusOn= { {
                      x: 0.5,
                      y: 0.5,
                      scale: 1,
                      animate: true
                    }}
                    series={{
                      regions: [{
                        scale: ['#3d3c3c', '#f2f2f2'],
                        normalizeFunction: 'polynomial',
                        values: mapData
                      }]
                    }}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
			</div>
		</div>
	);
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
	setUser: (data) => dispatch(setUser(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
