import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { SERVERURL } from '../../util/constant';
import swal from 'sweetalert';
import axios from 'axios';
import { connect } from 'react-redux';
import { setUser } from '../actions';

function Forgot(props) {
	const [loginData, setLoginData] = React.useState({ email: '' });
	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	const loginSumbit = (e) => {
		e.preventDefault();
		if (!validateEmail(loginData.email)) {
			swal('Please give valid input', 'validation fail', 'warning');
		}
		axios
			.post(`${SERVERURL}/auth/reset`, loginData)
			.then((response) => {
				if (response.status === 200) {
					//verfication success
					swal(
						'An email has been sent',
						'reset password with provided link',
						'success'
					);
				}
			})
			.catch((err) => {
				console.log(err);
				if (err.response.status === 400) {
					swal('registeration not done', 'email not found', 'warning');
				} else {
					swal('server error', '', 'error');
				}
			});
		console.log(loginData);
	};
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				allignItem: 'center',
				backgroundImage:'linear-gradient(#fefeff,#39ACE3)',
			}}
		>
			<div className="d-flex align-items-center auth px-0">
				<div className="row w-100 mx-0">
					<div className="col-lg-4 mx-auto">
						<div className="card text-left py-5 px-4 px-sm-5">
							<div
								className="brand-logo"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<img
									src={require('../../assets/images/ICAMP.png')}
									alt="logo"
									style={{
										height: '100px',
										width: '120px',
									}}
								/>
							</div>

							<h6
								className="font-weight-light"
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								Forgot password
							</h6>
							<Form className="pt-3">
								<Form.Group className="d-flex search-field">
									<Form.Control
										name="email"
										type="email"
										placeholder="kiit email id"
										size="lg"
										className="h-auto"
										onChange={(e) =>
											setLoginData({ ...loginData, email: e.target.value })
										}
									/>
								</Form.Group>

								<div className="mt-3">
									{/* <Link
										className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
										to="/dashboard"
									> */}
									<button
										className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
										onClick={loginSumbit}
									>
										send email
									</button>

									{/* </Link> */}
								</div>
								<div className="my-2 d-flex justify-content-between align-items-center">
									<a
										href="/login"
										onClick={(event) => event.preventDefault()}
										className="auth-link text-muted"
									>
										<Link to="/login"> back to login</Link>
									</a>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
	setUser: (data) => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);

//export default Login;
