import store from '../../src/app/store';

const isAuthenticated = () => {
	const token = store.getState().user.token;

	// console.log(token);
	if (token && token !== 'null') {
		return true;
	} else {
		return false;
	}
};
export default isAuthenticated;
